<template>
  <div class="inforMation">
    <div style="margin-bottom: 16px">
      <span style="margin-left: 8px">
        <!-- <template v-if="hasSelected">{{ `Selected ${selectedRowKeys.length} items` }}</template> -->
      </span>
    </div>
    <a-table
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :columns="columns"
      :data-source="data"
       :expandRowByClick="true"
    >
      <p
        slot="expandedRowRender"
        slot-scope="record"
        style="margin-left: -20px"
      >{{ record.description }}</p>
    </a-table>
    <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="startGet">已读</a-button>
    <a-button type="primary" :disabled="!hasSelected" :loading="loading" @click="startDelete">删除</a-button>
  </div>
</template>
<script>
const columns = [
  {
    title: "标题",
    dataIndex: "titel",
    key: "titel",
  },
  {
    title: "发件人",
    dataIndex: "name",
    key: "name",
    width: "16%",
  },
  {
    title: "发布时间",
    dataIndex: "dataTime",
    width: "16%",
    key: "dataTime",
  },
];

const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    titel: `Edward King ${i}`,
    name: 32,
    dataTime: `London, Park Lane no. ${i}`,
    description: `尊敬的开发者：`
  });
}

export default {
  data() {
    return {
      data,
      columns,
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  methods: {
    startGet() {
      this.loading = true;
      // ajax request after empty completing
      setTimeout(() => {
        this.loading = false;
        this.selectedRowKeys = [];
      }, 1000);
    },
    startDelete() {
      var removeData = this.selectedRowKeys
      for (var i = 0; i <= removeData.length; i++) {
        if (this.selectedRowKeys !== "") {
          this.data.splice(this.removeData, 1);
          this.selectedRowKeys = [];
        } else {
          // console.log(this.selectedRowKeys);
        }
      }
      // this.loading = true;
      // ajax request after empty completing
      // setTimeout(() => {
      //   this.loading = false;
      //   this.selectedRowKeys = [];
      // }, 1000);
    },
    onSelectChange(selectedRowKeys) {
      // console.log(this.data)
      // console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>
<style scoped>
.inforMation {
  padding: 15px;
}
</style>